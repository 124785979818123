<template>
  <HighlightableInput
    ref="textarea"
    rows="1"
    :name="name"
    :value="value"
    :placeholder="placeholder"
    :class="{ invalid: invalid, valid: valid }"
    :required="required"
    :highlight-style="highlightStyle"
    :highlight-enabled="highlightEnabled"
    :highlight="highlight"
    spellcheck="false"
    @input="$emit('input', $event);"
    @blur.native="onBlur"
  />
</template>
<script lang="ts">
// import HighlightableInput from "vue-highlightable-input"
import BaseInput from '@/components/atoms/BaseInput.vue'
import HighlightableInput from '@/components/atoms/HighlightableInput.vue'
import Vue from 'vue'

export default Vue.extend({
  components: {
    HighlightableInput
  },
  extends: BaseInput,
  props: {
    highlightEnabled: {
      type: Boolean,
      default: true
    },
    highlightStyle: {
      type: String,
      default: ''
    },
    highlight: {
      type: Array,
      default: () => [
        { text: /\B(#[a-zA-Z]+\b)/g },
        { text: /\B(@[a-zA-Z]+\b)/g }
      ]
    }
  },
  data () {
    return {
      latestValue: null
    }
  },
  methods: {
    onBlur () {
      this.setValue(this.$refs.textarea.internalValue)
    }
  }
})
</script>
