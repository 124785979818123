<template>
  <div style="flex-grow: 1" class="form-dark">
    <JsonEditor
      v-if="!editRaw"
      :options="{
        confirmText: 'confirm',
        cancelText: 'cancel',
      }"
      :objData="tmpValue"
      v-model="tmpValue"
    />
    <textarea
      v-if="editRaw"
      :value="JSON.stringify(tmpValue, null, 2)"
      @change="onRawChange"
      @keyup="onRawChange"
    />
    <p v-if="rawError">
      Raw JSON is invalid.
    </p>
    <Button @click="editRaw = !editRaw" class="small">Mode</Button>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'

import JsonEditor from 'vue-json-edit'

Vue.use(JsonEditor)
import BaseInput from '@/components/atoms/BaseInput.vue'
import Button from "@/components/atoms/Button.vue";
export default Vue.extend({
  components: { Button},
  extends: BaseInput,
  props: {
    useArrayKey: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      tmpValue: { },
      editRaw: true,
      rawError: false
    }
  },
  watch: {
    value: {
      handler(val){
        if(Array.isArray(val)){
          this.tmpValue = {
            items: val
          }
        }else{
          this.tmpValue = val
        }
      },
      immediate: true
    },
    tmpValue(value){
      console.log('new value', value);
      if(Array.isArray(this.value)){
        this.setValue(value.items)
      }else{
        this.setValue(value)
      }
    }
  },
  methods: {
    onRawChange(e){
      try{
        this.tmpValue = JSON.parse(e.target.value)
        this.rawError = false;
      } catch(e){
        this.rawError = true;
      }
    }
  }
})

</script>
