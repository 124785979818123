<template>
  <div class="input-error">
    <slot />
  </div>
</template>
<script lang="ts">
import Vue from 'vue'

export default Vue.extend({

})

</script>
<style lang="less" scoped>

    @import '../../less/variables';

    .input-error {
        font-weight: 600;
        margin-bottom: 1em;
        margin-top: 0.5em;
        text-align: center;
        animation: bouncedown 500ms;
        box-sizing: border-box;
    }

</style>
