<template>
  <label>
    <input
      :name="name"
      type="hidden"
      value="false"
    >
    <input
      ref="checkbox"
      :placeholder="placeholder"
      :class="{ invalid: invalid, valid: valid }"
      :checked="value"
      :name="name"
      value="true"
      type="checkbox"
      @change="onChange"
    >
    <span />
  </label>
</template>
<script lang="ts">
import Vue from 'vue'
import BaseInput from '@/components/atoms/BaseInput.vue'
export default Vue.extend({
  extends: BaseInput,
  methods: {
    onChange (e) {
      this.$emit('change', e.target.checked)
    }
  }
})
</script>

<style scoped lang="less">

</style>
