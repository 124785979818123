<template>
  <div style="text-align: left" class="input-select-checkbox">
    <div v-for="(option, index) in options" :key="`${uniqueKey}-${index}-${option}`" @click="toggleSelection(option)" :class="{option: true, checked: value.includes(option) }" :style="{ width }">
      <span :class="{checkbox: true }">
        <IconSvg name="check" />
      </span>
      {{option}}
    </div>
  </div>
</template>
<script lang="ts">
import BaseInput from '@/components/atoms/BaseInput.vue'
import IconSvg from "@/components/atoms/IconSvg.vue";
import Vue from 'vue'
export default Vue.extend({
  extends: BaseInput,
  components: {
    IconSvg
  },
  props: {
    options: {
      type: Array
    },
    columns: {
      type: Number,
      default: 2
    },
    uniqueKey: {
      type: String,
      default: null
    }
  },
  computed: {
    width(){
      return `${100 / this.columns}%`
    }
  },
  methods: {
    toggleSelection(option){
      if(this.value.includes(option)){
        this.setValue([...this.value].filter(v => v != option))
      }else{
        this.setValue([...this.value, option])
      }
    }
  },
})
</script>

<style lang="less" scoped>
@import '../../less/variables';

.option{
  width: 50%;
  display: inline-flex;
  align-items: center;
  margin:0.5em 0;
  cursor: pointer;
}
.checkbox{
  height: 1.5em;
  width: 1.5em;
  border-radius: 0.25em;
  border: 1px solid @grayMedium;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5em;
  .icon{
    visibility: hidden;
  }
}
.checked .checkbox{
  background: black;
  border-color: black;
  color: white;
  .icon{
    visibility: visible;
  }
}
</style>
