<template>
  <input
    v-rules="{ minLength: 4 }"
    :name="name"
    :value="value"
    :placeholder="placeholder"
    :class="{ invalid: invalid, valid: valid }"
    type="password"
    @change="onChange"
    @keypress.enter="onEnter"
  >
</template>
<script lang="ts">
import Vue from 'vue'

import BaseInput from '@/components/atoms/BaseInput.vue'

export default Vue.extend({
  extends: BaseInput,
  methods: {
    minLength (value, length) {
      return value && value.length >= length
    }
  }
})

</script>
