<template>
  <div
    :class="{'highlightable-input': true, 'showing-placeholder': showPlaceholder}"
    contenteditable="true"
    @focus="onFocus"
    @blur="onBlur"
  >
    {{ placeholder }}
  </div>
</template>

<script>

import IntervalTree from 'node-interval-tree'
import Vue from 'vue'

const tagsToReplace = {
  // ' ': '&nbsp;',
  // '&': '&amp;',
  // '<': '&lt;',
  // "\n": '<br />',
  // '>': '&gt;'
}

function debounce (func, wait, immediate) {
  let timeout
  return function () {
    const context = this; const args = arguments
    const later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

function isUndefined (value) {
  return value === undefined
}

export default Vue.extend({
  props: {
    highlight: {
      type: Array,
      default: () => []
    },
    value: {
      type: String,
      default: ''
    },
    highlightStyle: {
      type: [String, Object],
      default: 'background-color:yellow'
    },
    highlightEnabled: {
      type: Boolean,
      default: true
    },
    highlightDelay: {
      type: Number,
      default: 500 // This is milliseconds
    },
    caseSensitive: {
      type: Boolean,
      default: false
    },
    fireOn: {
      type: String,
      default: 'keydown'
    },
    fireOnEnabled: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      internalValue: '',
      htmlOutput: '',
      debouncedHandler: null,
      showPlaceholder: true
    }
  },

  watch: {

    highlightStyle () {
      this.processHighlights()
    },

    highlight () {
      // this.processHighlights()
    },

    value () {
      if (this.internalValue != this.value) {
        this.internalValue = this.value
        this.processHighlights()
      }
    },

    highlightEnabled () {
      this.processHighlights()
    },

    caseSensitive () {
      this.processHighlights()
    },

    htmlOutput () {
      const selection = this.saveSelection(this.$el)
      this.$el.innerHTML = this.htmlOutput
      this.restoreSelection(this.$el, selection)
    }
  },
  mounted () {
    if (this.fireOnEnabled) { this.$el.addEventListener(this.fireOn, this.handleChange) }
    this.internalValue = this.value
    this.processHighlights()
    this.onBlur()
  },

  methods: {

    onFocus () {
      if (this.$el.innerText.trim() == this.placeholder) {
        // clear it out
        this.$el.innerText = ''
      }
      this.showPlaceholder = false
    },

    onBlur () {
      if (this.$el.innerText.trim() == '') {
        // clear it out
        this.$emit('input', '')
        this.$el.innerText = this.placeholder
        this.showPlaceholder = true
      } else {
        this.showPlaceholder = false
      }
      this.handleChange()
      this.$emit('blur')
    },

    handleChange () {
      this.debouncedHandler = debounce(function () {
        // const html = this.$el.innerHTML.replace(/<(?!div\s*\/?)[^>]+>/g, '').replace(/<div>/g,"\n").trim();
        const html = this.$el.innerHTML
          .replace(/<(?!(br|div)\s*\/?)[^>]+>/g, '') // replace all tags except br and div
          .replace(/<div><br>/g, '<br>') // replace all divs with brs
          .replace(/<div>/g, '<br>') // replace all divs with brs
          .replace(/<br>/g, '\n') // replace brs with new lines
          .trim()
        if (this.internalValue !== html) {
          this.internalValue = html
          this.processHighlights()
        }
      }, this.highlightDelay, true)
      this.debouncedHandler()
    },

    processHighlights () {
      if (!this.highlightEnabled) {
        this.htmlOutput = this.internalValue
        this.$emit('input', this.internalValue)
        return
      }

      const intervalTree = new IntervalTree()
      // Find the position ranges of the text to highlight
      let highlightPositions = []
      const sortedHighlights = this.normalizedHighlights()
      if (!sortedHighlights) { return }

      for (let i = 0; i < sortedHighlights.length; i++) {
        var highlightObj = sortedHighlights[i]

        let indices = []
        if (highlightObj.text) {
          if (typeof (highlightObj.text) === 'string') {
            indices = this.getIndicesOf(highlightObj.text, this.internalValue, isUndefined(highlightObj.caseSensitive) ? this.caseSensitive : highlightObj.caseSensitive)
            indices.forEach(start => {
              const end = start + highlightObj.text.length - 1
              this.insertRange(start, end, highlightObj, intervalTree)
            })
          }

          if (Object.prototype.toString.call(highlightObj.text) === '[object RegExp]') {
            indices = this.getRegexIndices(highlightObj.text, this.internalValue)
            indices.forEach(pair => {
              this.insertRange(pair.start, pair.end, highlightObj, intervalTree)
            })
          }
        }

        if (highlightObj.start != undefined && highlightObj.end != undefined && highlightObj.start < highlightObj.end) {
          const start = highlightObj.start
          const end = highlightObj.end - 1
          this.insertRange(start, end, highlightObj, intervalTree)
        }
      }

      highlightPositions = intervalTree.search(0, this.internalValue.length)
      highlightPositions = highlightPositions.sort((a, b) => a.start - b.start)

      // Construct the output with styled spans around the highlight text
      let result = ''
      let startingPosition = 0
      for (let k = 0; k < highlightPositions.length; k++) {
        const position = highlightPositions[k]
        result += this.safe_tags_replace(this.internalValue.substring(startingPosition, position.start))
        result += "<span style='" + highlightPositions[k].style + "'>" + this.safe_tags_replace(this.internalValue.substring(position.start, position.end + 1)) + '</span>'
        startingPosition = position.end + 1
      }

      // In case we exited the loop early
      if (startingPosition < this.internalValue.length) { result += this.safe_tags_replace(this.internalValue.substring(startingPosition, this.internalValue.length)) }

      // Stupid firefox bug
      if (result[result.length - 1] == ' ') {
        result = result.substring(0, result.length - 1)
        result += '&nbsp;'
      }

      // add line breaks back in
      result = result.replace(/\n/g, '<br />')
      this.htmlOutput = result
      this.$emit('input', this.internalValue)
    },

    insertRange (start, end, highlightObj, intervalTree) {
      const overlap = intervalTree.search(start, end)
      const maxLengthOverlap = overlap.reduce((max, o) => { return Math.max(o.end - o.start, max) }, 0)
      if (overlap.length == 0) {
        intervalTree.insert(start, end, { start: start, end: end, style: highlightObj.style })
      } else if ((end - start) > maxLengthOverlap) {
        overlap.forEach(o => {
          intervalTree.remove(o.start, o.end, o)
        })
        intervalTree.insert(start, end, { start: start, end: end, style: highlightObj.style })
      }
    },

    normalizedHighlights () {
      if (this.highlight == null) { return null }

      if (Object.prototype.toString.call(this.highlight) === '[object RegExp]' || typeof (this.highlight) === 'string') { return [{ text: this.highlight }] }

      if (Object.prototype.toString.call(this.highlight) === '[object Array]' && this.highlight.length > 0) {
        const globalDefaultStyle = typeof (this.highlightStyle) === 'string' ? this.highlightStyle : (Object.keys(this.highlightStyle).map(key => key + ':' + this.highlightStyle[key]).join(';') + ';')

        const regExpHighlights = this.highlight.filter(x => x == Object.prototype.toString.call(x) === '[object RegExp]')
        const nonRegExpHighlights = this.highlight.filter(x => x == Object.prototype.toString.call(x) !== '[object RegExp]')
        return nonRegExpHighlights.map(h => {
          if (h.text || typeof (h) === 'string') {
            return {
              text: h.text || h,
              style: h.style || globalDefaultStyle,
              caseSensitive: h.caseSensitive
            }
          } else if (h.start != undefined && h.end != undefined) {
            return {
              style: h.style || globalDefaultStyle,
              start: h.start,
              end: h.end,
              caseSensitive: h.caseSensitive
            }
          } else {
            console.error('Please provide a valid highlight object or string')
          }
        }).sort((a, b) => (a.text && b.text) ? a.text > b.text : ((a.start == b.start ? (a.end < b.end) : (a.start < b.start)))).concat(regExpHighlights)
        // We sort here in ascending order because we want to find highlights for the smaller strings first
        // and then override them later with any overlapping larger strings. So for example:
        // if we have highlights: g and gg and the string "sup gg" should have only "gg" highlighted.
        // RegExp highlights are not sorted and simply concated (this could be done better  in the future)
      }

      console.error('Expected a string or an array of strings')
      return null
    },

    // Copied from: https://stackoverflow.com/questions/5499078/fastest-method-to-escape-html-tags-as-html-entities
    safe_tags_replace (str) {
      return str.replace(/[&<>]/g, this.replaceTag)
    },

    replaceTag (tag) {
      return tagsToReplace[tag] || tag
    },

    getRegexIndices (regex, str) {
      if (!regex.global) {
        console.error('Expected ' + regex + ' to be global')
        return []
      }

      regex = RegExp(regex)
      const indices = []
      let match = null
      while ((match = regex.exec(str)) != null) {
        indices.push({ start: match.index, end: match.index + match[0].length - 1 })
      }
      return indices
    },

    // Copied verbatim because I'm lazy:
    // https://stackoverflow.com/questions/3410464/how-to-find-indices-of-all-occurrences-of-one-string-in-another-in-javascript
    getIndicesOf (searchStr, str, caseSensitive) {
      const searchStrLen = searchStr.length
      if (searchStrLen == 0) {
        return []
      }
      let startIndex = 0; let index; const indices = []
      if (!caseSensitive) {
        str = str.toLowerCase()
        searchStr = searchStr.toLowerCase()
      }
      while ((index = str.indexOf(searchStr, startIndex)) > -1) {
        indices.push(index)
        startIndex = index + searchStrLen
      }
      return indices
    },

    // Copied but modifed slightly from: https://stackoverflow.com/questions/14636218/jquery-convert-text-url-to-link-as-typing/14637351#14637351
    saveSelection (containerEl) {
      let start
      if (window.getSelection && document.createRange) {
        const selection = window.getSelection()
        if (!selection || selection.rangeCount == 0) { return }
        const range = selection.getRangeAt(0)
        const preSelectionRange = range.cloneRange()
        preSelectionRange.selectNodeContents(containerEl)
        preSelectionRange.setEnd(range.startContainer, range.startOffset)
        start = preSelectionRange.toString().length

        return {
          start: start,
          end: start + range.toString().length
        }
      } else if (document.selection) {
        const selectedTextRange = document.selection.createRange()
        const preSelectionTextRange = document.body.createTextRange()
        preSelectionTextRange.moveToElementText(containerEl)
        preSelectionTextRange.setEndPoint('EndToStart', selectedTextRange)
        start = preSelectionTextRange.text.length

        return {
          start: start,
          end: start + selectedTextRange.text.length
        }
      }
    },

    // Copied but modifed slightly from: https://stackoverflow.com/questions/14636218/jquery-convert-text-url-to-link-as-typing/14637351#14637351
    restoreSelection (containerEl, savedSel) {
      if (!savedSel) { return }

      if (window.getSelection && document.createRange) {
        let charIndex = 0; const range = document.createRange()
        range.setStart(containerEl, 0)
        range.collapse(true)
        const nodeStack = [containerEl]; let node; let foundStart = false; let stop = false

        while (!stop && (node = nodeStack.pop())) {
          if (node.nodeType == 3) {
            const nextCharIndex = charIndex + node.length
            if (!foundStart && savedSel.start >= charIndex && savedSel.start <= nextCharIndex) {
              range.setStart(node, savedSel.start - charIndex)
              foundStart = true
            }
            if (foundStart && savedSel.end >= charIndex && savedSel.end <= nextCharIndex) {
              range.setEnd(node, savedSel.end - charIndex)
              stop = true
            }
            charIndex = nextCharIndex
          } else {
            let i = node.childNodes.length
            while (i--) {
              nodeStack.push(node.childNodes[i])
            }
          }
        }

        const sel = window.getSelection()
        sel.removeAllRanges()
        sel.addRange(range)
      } else if (document.selection) {
        const textRange = document.body.createTextRange()
        textRange.moveToElementText(containerEl)
        textRange.collapse(true)
        textRange.moveEnd('character', savedSel.end)
        textRange.moveStart('character', savedSel.start)
        textRange.select()
      }
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
  @import '../../less/variables';

  .highlightable-input.showing-placeholder{
    color: @grayMedium;
  }
</style>
