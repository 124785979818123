<script lang="ts">

/**
 * @module FormMixin
 *
 * [FormMixin] serves two primary purposes.
 * 1. Define base components for [Forms]
 * 2. Maintain key [Input] validation pass/failure information
 *
 * ABOUT VALIDATION
 * FormMixin listens to 'change' and 'error' events from [Inputs]
 * to maintain key validation pass/failure information to the [Form].
 * This is primarily used to hide/show [Errors], or other components
 * based on which [Input] have failed validation, if any.
 *
 * @inputErrors {object} contains which [Input] have failed validation
 * and what error they returned.
 *
 * @formHasError {boolean} contains whether or not ANY [Input]
 * is currently failing validation
 *
 * ==========================================
 */

import Button from '@/components/atoms/Button.vue'
import Error from '@/components/atoms/Error.vue'
import InputCheckbox from '@/components/atoms/InputCheckbox.vue'
import InputEmail from '@/components/atoms/InputEmail.vue'
import InputNumber from '@/components/atoms/InputNumber.vue'
import InputPassword from '@/components/atoms/InputPassword.vue'
import InputPhone from '@/components/atoms/InputPhone.vue'
import InputSelect from '@/components/atoms/InputSelect.vue'
import InputSelectCheckbox from '@/components/atoms/InputSelectCheckbox.vue'
import InputSlider from '@/components/atoms/InputSlider.vue'
import InputText from '@/components/atoms/InputText.vue'
import InputTextArea from '@/components/atoms/InputTextArea.vue'
import InputTextAreaHighlightable from '@/components/atoms/InputTextAreaHighlightable.vue'
import InputJson from "@/components/atoms/InputJson.vue";
import Vue from 'vue'

export default Vue.extend({
  components: {
    Error,
    Button,
    InputEmail,
    InputPassword,
    InputText,
    InputCheckbox,
    InputNumber,
    InputTextArea,
    InputSelect,
    InputSelectCheckbox,
    InputSlider,
    InputPhone,
    InputJson,
    InputTextAreaHighlightable
  },
  directives: {
    formRules: function (input, binding, vnode) {
      if (typeof binding.value === 'function') {
        // @ts-ignore
        vnode.context.formRules[binding.expression] = true
      } else if (typeof binding.value === 'object') {
        // @ts-ignore
        vnode.context.formRules = binding.value
      }
    }
  },
  props: {
    initialFormData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    submitButtonLabel: {
      type: String,
      default: null
    },
    submitOnChange: {
      type: Boolean,
      default: false
    },
    validate: {
      type: String,
      default: 'all',
      validator: value => ['all', 'changes'].includes(value)
    },
    create: {
      type: [Function, null],
      required: false,
      default: null
    },
    update: {
      type: [Function, null],
      required: false,
      default: null
    },
    delete: {
      type: [Function, null],
      required: false,
      default: null
    }
  },
  data: function () {
    return {
      /*
            * @inputErrors {object} contains which [Input] have failed validation
            * and what error they returned.
            * */
      inputErrors: {},
      /*
            * @formErrors {object} contains form formRules have failed validation
            * and what error they returned.
            * */
      formErrors: {},
      /*
             * @formHasError {boolean} contains whether or not ANY [Input]
             * is currently failing validation
             * */
      formHasError: false,
      /*
             * @inputs {array} contains a list of all descendent [Input]s
             * */
      inputs: [],
      /*
             * @changedFormData {object} contains all changed form values
             * */
      changedFormData: {

      },
      loading: false,
      lastSaved: null
    }
  },
  computed: {
    error () {
      return this.inputErrors
    },
    formData () {
      return Object.assign(Object.assign({}, this.initialFormData), this.changedFormData)
    },
    formDataHasChanged () {
      return Object.keys(this.changedFormData).length > 0
    },
    isNew () {
      return !this.formData._id
    }
  },
  watch: {
    lastSaved(){
      // this.$store.dispatch('app/setStatusMessage', 'Saved just now');
    }
  },
  mounted () {
    /**
     * Automatically bind 'change' and 'error' event listeners to [Inputs]
     */
    const getAllDescendantInputs = component => {
      let inputs = []
      component.$children.forEach(child => {
        if (child.rules) {
          inputs.push(child)
        }
        // This is the recursive part
        if (child.$children) {
          inputs = inputs.concat(getAllDescendantInputs(child))
        }
      })
      return inputs
    }

    this.inputs = getAllDescendantInputs(this)

    this.inputs.forEach(input => {
      input.$on('change', value => {
        this.onChange(input.name, value)
      }).$on('error', value => {
        this.onError(input.name, value)
      }).$on('enter', () => {
        this.validateAndSubmit()
      })
    })
    if (this.inputs.length === 0) {
      console.warn('MixinForm: Zero inputs found. If you have conditionally-shown inputs use v-show instead of v-if.')
    }
  },
  methods: {
    /**
     * Method to validate form, and submit if it passes
     * @param {string} inputName - The name of the input that's changed
     * @param {object} event - details of the error event
     */
    validateAndSubmit () {
      if (this.allInputsAreValid() && this.formRulesAreValid()) {
        this.$emit('submit', {
          data: { ...this.formData },
          changedData: { ...this.changedFormData }
        })

        if (!this.isNew && this.update && typeof this.update === 'function') {
          // Call update function
          this.loading = true
          this.update(Object.assign(
              { ...this.changedFormData },
              { _id: this.formData._id }
          )).then(() => {
            this.loading = false
            this.lastSaved = new Date();
            this.$emit('update', {
              data: { ...this.formData },
              changedData: { ...this.changedFormData }
            })
          }).catch(error => {
            console.log(error);
            this.loading = false
          })
        }

        if (this.isNew && this.create && typeof this.create === 'function') {
          // Call create function
          this.loading = true
          Vue.delete(this.formErrors, 'create')
          this
              .create(this.formData)
              .then(resultingObject => {
                console.log({resultingObject})
                this.loading = false
                this.lastSaved = new Date();
                this.$emit('create', resultingObject)
              })
              .catch(error => {
                console.log(error)
                if(error.message){
                  this.formErrors = { create: error.message };
                  this.$emit('error', {
                    rule: 'create',
                    message: error.message
                  })
                }
                this.loading = false
              })
        }
      } else {
        this.$emit('error', this.inputErrors)
      }
    },
    /**
     * Returns whether or not all child [Inputs] have passed validation
     * @return {boolean}
     */
    allInputsAreValid () {
      this.validateAllInputs()
      let allInputsAreValid = true
      Object.values(this.inputErrors).forEach(val => {
        if (val) {
          allInputsAreValid = false
        }
      })
      return allInputsAreValid
    },
    /**
     * Returns whether form formRules from v-formRules directive are valid
     * @return {boolean}
     */
    formRulesAreValid () {
      if (this.formRules) {
        this.formErrors = {}
        // console.log('Looping over form formRules', this.formRules.length)
        this.formRules.forEach(ruleName => {
          if (Object.prototype.hasOwnProperty.call(this, ruleName) && typeof this[ruleName] === 'function') {
            // console.log('found rule function', ruleName)
            const results = this[ruleName]()
            // console.log('rule results are', results)
            if (results !== true) {
              this.formErrors[ruleName] = results
              // console.log('emitting form error')
              this.$emit('error', {
                rule: ruleName,
                message: results
              })
            }
          } else {
            console.warn(`ReferenceError: Form validation rule ${ruleName} is referenced but not defined`)
          }
        })
        if (Object.keys(this.formErrors).length > 0) {
          // console.log('form has errors', this.formErrors)
          this.formHasError = true
          return false
        } else {
          // console.log('form has no errors', this.formErrors)
          return true
        }
      } else {
        return true
      }
    },
    /**
     * Executes validation checks on ALL child [Inputs]
     */
    validateAllInputs () {
      if (this.validate === 'all') {
        // Validate all inputs
        this.inputs.forEach(input => input.validate())
      } else if (this.validate === 'changes') {
        // Only run validation checks on changed values
        const changedInputs = this.inputs.filter(input => {
          return Object.prototype.hasOwnProperty.call(this.changedFormData, input.name)
        })
        changedInputs.forEach(input => input.validate())
      }
    },
    /**
     * Event handler for 'change' events from all inputs
     * @param {string} inputName - The name of the input that's changed
     * @param {string} newValue - The new value of the input
     */
    onChange (field, value) {
      console.log(`onChange`, field, value);
      this.setFieldValue(field, value)
    },
    /**
     * Event handler for 'error' events from all inputs
     * @param {string} inputName - The name of the input that's changed
     * @param {object} event - details of the error event
     */
    onError (inputName, event) {
      // Set this.inputErrors[inputName] = errorName (rule broken, I.E. minLength)
      this.$set(this.inputErrors, inputName, event.error)
      this.formHasError = true
    },
    /**
     * Event handler for 'error' events from all inputs
     * @param {string} inputName - The name of the input that's changed
     * @param {object} event - details of the error event
     */
    setFormData (data) {
      Object.keys(data).forEach(field => {
        this.setFieldValue(field, data[field])
      })
    },
    resetForm () {
      this.changedFormData = {};
    },
    setInitialFieldValue (field, value) {
      Vue.set(this.initialFormData, field, value)
    },
    setFieldValue (field, value) {
      if (this.formData[field] !== value) {
        Vue.delete(this.inputErrors, field)
        this.formHasError = false
        console.log(`changing form data`, field, value);
        this.$set(this.changedFormData, field, value)
        this.$emit('change', {field, value})
        if (this.submitOnChange) {
          // $nextTick is CRITICAL for validation to run properly
          this.$nextTick(() => {
            this.validateAndSubmit()
          })
        }
      }
    }
  }
})
</script>
