<template>
  <VueSlider
    :value="value"
    :tooltip="tooltip"
    :tooltip-formatter="tooltipFormatter"
    :data="data"
    :min="min"
    :max="max"
    :marks="marks"
    :included="included"
    :enable-cross="enableCross"
    :hide-label="hideLabel"
    @change="setValue($event)"
  />
</template>
<script lang="ts">
import '@/less/vue-slider.less'

import BaseInput from '@/components/atoms/BaseInput.vue'
import Vue from 'vue'
import VueSlider from 'vue-slider-component'

Vue.component('VueSlider', VueSlider)

export default Vue.extend({
  extends: BaseInput,
  props: {
    tooltip: {
      type: String
    },
    tooltipFormatter: {
      type: Function
    },
    data: {
      type: Array
    },
    enableCross: {
      type: Boolean
    },
    hideLabel: {
      type: Boolean
    },
    included: {
      type: Boolean
    },
    marks: {
      type: Object
    },
    min: {
      type: Number
    },
    max: {
      type: Number
    }
  }
})
</script>

<style lang="less" scoped>

</style>
