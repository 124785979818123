<template>
  <textarea-autosize
    :name="name"
    :value="value"
    :placeholder="placeholder"
    :class="{ invalid: invalid, valid: valid }"
    :required="required"
    rows="2"
    @change.native="onChange"
    @keyup.native="onKeyUp"
    @keyup.enter="$emit('enter')"
  />
</template>
<script lang="ts">
import Vue from 'vue'
import TextareaAutosize from 'vue-textarea-autosize'
import BaseInput from '@/components/atoms/BaseInput.vue'

Vue.use(TextareaAutosize)

export default Vue.extend({
  extends: BaseInput
})

</script>
